import React from 'react';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';

const Olark = () => {
  return (
    <>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Olark is a popular live chat tool that comes with a lot of customization options. You can
          adjust the live chat window to match your brand, add automation rules, or work with handy
          transcripts to search for the information you need.
        </p>
        <p>
          What’s more, you can connect it to LiveSession and add session replay links automatically
          to your conversations.{' '}
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access recordings of a specific user directly from Olark</li>
          <li>See how the user has interacted with a website</li>
          <li>Get more context and provide support faster</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code. See a detailed tutorial in our{' '}
        <a href="/help/olark-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Olark);
